<template>
  <div>
    <v-row class="pa-0 ma-0">
      <v-col cols="12" lg="4" md="4" sm="12" class="pa-0 ma-0">
        <v-hover v-slot="{ hover }">
          <v-card
            @click="1"
            elevation="0"
            :class="{ 'on-hover': hover }"
            class="rounded-t-0"
          >
            <v-img
              class="align-center"
              height="600px"
              src="../../assets/photo1.jpg"
            >
              <v-icon
                :class="{ 'show-btns': hover }"
                :color="transparent"
                x-large
                class="d-flex justify-center plusicon"
                >mdi-plus</v-icon
              >
              <h2
                class="pa-12 white--text"
                style="position: absolute; bottom: 0"
                v-html="$t('home.informationtechnology')"
              ></h2>
            </v-img>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="12" class="pa-0 ma-0">
        <v-hover v-slot="{ hover }">
          <v-card
            @click="1"
            elevation="0"
            :class="{ 'on-hover': hover }"
            class="rounded-card"
          >
            <v-img
              class="align-center"
              height="600px"
              src="../../assets/photo_4.jpg"
            >
              <v-icon
                :class="{ 'show-btns': hover }"
                :color="transparent"
                x-large
                class="d-flex justify-center plusicon"
                >mdi-plus</v-icon
              >
              <h2
                class="pa-12 white--text"
                style="position: absolute; bottom: 0"
                v-html="$t('home.Systemofsynchronizationandcontrolboards')"
              ></h2>
            </v-img>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="12" class="pa-0 ma-0">
        <v-hover v-slot="{ hover }">
          <v-card
            @click="1"
            elevation="0"
            :class="{ 'on-hover': hover }"
            class="rounded-0"
          >
            <v-img
              class="rounded-0 align-center"
              height="600px"
              src="../../assets/327960048_1263491040940486_2839749168145609220_n.jpg"
            >
              <v-icon
                :class="{ 'show-btns': hover }"
                :color="transparent"
                x-large
                class="d-flex justify-center plusicon"
                >mdi-plus</v-icon
              >
              <h2
                class="pa-12 white--text"
                style="position: absolute; bottom: 0"
                v-html="$t('home.Electronicgates')"
              ></h2>
            </v-img>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      transparent: "rgba(255, 255, 255, 0)",
    };
  },
};
</script>

<style scoped>
.rounded-card {
  border-radius: 50px;
}
.v-card {
  transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.95;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0px;
}

.plusicon:hover {
  /* color: red !important; */
  transform: rotate(90deg);
}

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
</style>
